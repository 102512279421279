@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
.button {
  cursor: pointer;
  color: #fff;
  background: #19ca75;
  border: 1px solid #19ca75;
  border-radius: 1.875rem;
  justify-content: center;
  align-items: center;
  width: 16.8125rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: .9375rem;
  padding-bottom: .9375rem;
  font-size: 1.25rem;
  line-height: 1.875rem;
  display: flex;
}

.button:disabled {
  background: #d6e9dd;
  border-color: #d6e9dd;
}

.button:hover {
  background: #49f3a2;
  border-color: #49f3a2;
}

.button:active {
  background: #1ee083;
  border-color: #1ee083;
}

.button--light {
  color: #19ca75;
  background: none;
}

.button--light:disabled {
  color: #d6e9dd;
  background: none;
}

.button--light:hover {
  color: #49f3a2;
  background: none;
}

.button--light:active {
  color: #1ee083;
  background: none;
}

.button img {
  padding-left: .75rem;
}

section {
  text-align: center;
  background: #fbfcfd;
}

.section {
  padding-bottom: 6.125rem;
}

.container {
  box-sizing: border-box;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 0 40px;
}

.section__title {
  color: #3c415f;
  text-transform: uppercase;
  margin: 0;
  padding-top: 6.875rem;
  padding-bottom: 2.75rem;
  font-size: 3rem;
  font-weight: 700;
}

.section__description {
  padding-bottom: 4.4375rem;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

.show-form-btn {
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.header {
  z-index: 1;
  border-bottom: 1px solid #3c6096;
  width: 100%;
  height: 6.1875rem;
  transition: all .2s;
  position: fixed;
}

.header__container {
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
}

.header--open .burger-btn__icon {
  background: url("close.687151db.svg");
}

.header__burger {
  display: none;
  position: relative;
}

.header__logo {
  z-index: 1;
  margin-left: 1.5rem;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.header__logo img {
  width: 109px;
}

.header--scrolled {
  background: #0e1937;
}

.header--scrolled-light {
  background: #fbfcfd;
}

.header--scrolled-light .header__container > .nav .nav__link {
  color: #9497ab;
}

.header--scrolled-light .header__container > .nav .nav__link--active {
  text-shadow: 0 0 .4px #3c415f;
  color: #3c415f;
}

.burger-btn {
  box-sizing: content-box;
  width: 1.875rem;
  height: 1.875rem;
  padding: 5px;
  display: block;
}

.burger-btn__icon {
  background: url("burger.aa29712f.svg");
  border: none;
  width: 100%;
  height: 100%;
  display: block;
}

.nav {
  justify-content: center;
  align-items: center;
  display: flex;
}

.nav__link {
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  padding: .5rem 2rem;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: none;
  position: relative;
}

.nav__link:after {
  content: "";
  opacity: 0;
  background: #19ca75;
  border-radius: 50%;
  width: .375rem;
  height: .375rem;
  margin-left: -.1875rem;
  transition: all .3s;
  display: block;
  position: absolute;
  bottom: -.3125rem;
  left: 50%;
}

.nav__link.nav__link--active:after {
  opacity: 1;
}

.header__mobile-nav {
  backdrop-filter: blur(4px);
  background-color: #0e1937f2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
}

.header__mobile-nav .nav {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.625rem;
}

.header__mobile-nav .nav__link {
  padding: .9375rem .625rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.header__mobile-nav .nav__link:after {
  transition: none;
}

.header__mobile-nav .nav__link--active:after {
  margin-top: 1.875rem;
  margin-left: -.75rem;
  inset: 0;
}

.header--open .header__mobile-nav {
  display: flex;
}

.main {
  box-sizing: border-box;
  background: #0e1937;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.main__banner {
  text-align: center;
  color: #fff;
  background: url("banner-bg.min.5ab76e1e.png") center / cover;
  margin-bottom: -4.5rem;
  padding: 18rem 5rem;
  position: relative;
}

.main__footer {
  border-top: 1px solid #3c6096;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5.25rem;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main__footer-button {
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
}

.main__title {
  margin-top: 0;
  margin-bottom: 1.75rem;
  font-size: 1.25rem;
  font-weight: 400;
}

.main__description {
  margin-top: 0;
  margin-bottom: 3rem;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2.9375rem;
}

.main video {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  inset: 0;
}

.networks .stake__title {
  color: #19ca75;
  padding-bottom: 2.9375rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.networks .stake__description {
  margin-bottom: 4.625rem;
  font-size: 1.25rem;
}

.nets {
  padding-bottom: 3.375rem;
}

.nets__title {
  text-align: left;
  margin-bottom: 2.625rem;
  font-size: 2.25rem;
  font-weight: 700;
}

.nets a {
  color: inherit;
  text-decoration: none;
}

.nets a .net-item__button:after {
  content: "";
  background: url("link-icon.274cc856.svg");
  width: .5rem;
  height: .5rem;
  margin-bottom: 3px;
  margin-left: 3px;
  display: block;
}

.nets__carousel {
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  margin-bottom: 2.125rem;
  display: grid;
}

.net-item {
  background: #fff;
  flex-direction: column;
  display: flex;
}

.net-item__image {
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: stretch;
  transition: all .2s;
  display: flex;
}

.net-item__caption {
  flex-grow: 0;
  flex-shrink: 0;
  padding-bottom: 3.375rem;
  font-size: 1.25rem;
}

.net-item__svg {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.net-item__button {
  text-transform: uppercase;
  background: #fcfaff;
  justify-content: center;
  align-items: center;
  height: 0;
  transition: all .2s;
  display: flex;
  overflow: hidden;
}

.nets--main .net-item {
  aspect-ratio: 1;
}

.nets--main .net-item__svg svg, .nets--main .net-item img {
  max-width: 6.25rem;
  max-height: 6.25rem;
}

.nets--main .net-item:hover .net-item__button {
  height: 3.5rem;
  text-decoration: none;
}

.nets--test {
  padding-bottom: 0;
}

.nets--test .net-item {
  aspect-ratio: 1.79;
}

.nets--test .net-item__svg svg, .nets--test .net-item img {
  max-width: 3.625rem;
  max-height: 3.625rem;
}

.nets--test .net-item__caption {
  padding-bottom: 1.75rem;
  font-size: 1rem;
}

.nets--test .net-item__button {
  font-size: .75rem;
}

.nets--test .net-item:hover .net-item__button {
  height: 2.8125rem;
  text-decoration: none;
}

.team {
  color: #3c415f;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  margin-bottom: 2.25rem;
  display: grid;
}

.team__title {
  margin-bottom: 2.625rem;
  font-size: 2.5rem;
  font-weight: 700;
}

.member {
  font-size: 1.25rem;
  font-weight: 400;
  position: relative;
}

.member__name {
  padding-bottom: 5px;
}

.member__image {
  width: 100%;
}

.member__info {
  color: #fff;
  opacity: 0;
  background-color: #0000006e;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 0;
  transition: all .2s;
  display: flex;
  position: absolute;
  inset: 0;
}

.member:hover .member__info {
  opacity: 1;
  padding-bottom: 6.125rem;
}

.contact {
  color: #fff;
  background: #0e1937 url("footer-bg.min.ec7c4190.png") center / cover;
  padding-bottom: 4.125rem;
}

.contact .section__title {
  color: #fff;
  padding-top: 8.75rem;
}

.form {
  grid-column-gap: 1.25rem;
  grid-row-gap: .625rem;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(2, 1fr);
  max-width: 56.25rem;
  margin-top: -.5rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  position: relative;
}

.form-success {
  opacity: 0;
  justify-content: center;
  align-items: center;
  transition: opacity .2s ease-in;
  display: none;
  position: absolute;
  inset: 0;
}

.form-control {
  color: #686c6b;
  box-sizing: border-box;
  background: none;
  border: 1.3px solid #686c6b;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 1.125rem;
  font-size: .875rem;
}

.form-control:hover {
  background: #172240;
}

.form-control:focus {
  background: #fff;
  outline: none;
}

.form-group {
  opacity: 1;
  width: 100%;
  height: 3.75rem;
  transition: opacity .2s ease-in;
}

.form-group--message {
  grid-area: 1 / 2 / 3 / 3;
  height: 9rem;
}

.form-group--message textarea {
  resize: none;
}

.form-group--name {
  grid-area: 1 / 1 / 2 / 2;
}

.form-group--email {
  grid-area: 2 / 1 / 3 / 2;
}

.form-group--checkbox {
  grid-area: 3 / 1 / 4 / 2;
}

.form-group--submit {
  grid-area: 3 / 2 / 4 / 3;
}

.form.success .form-group {
  opacity: 0;
}

.form.success .form-success {
  opacity: 1;
  display: flex;
}

.form-group--checkbox {
  padding-top: .75rem;
}

.submit-button {
  width: 100%;
}

.social {
  margin-top: 3.4375rem;
}

.social-description {
  color: #6d7076;
  margin-bottom: 1.625rem;
  font-size: 1.25rem;
}

.social-icons {
  justify-content: center;
  align-items: center;
  display: flex;
}

.social-link {
  border: 1px solid #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2.80687rem;
  height: 2.80687rem;
  margin: 0 .375rem;
  display: flex;
}

.social-link:hover {
  background: #172240;
}

.social-link:active {
  background: #26304b;
}

.footer {
  justify-content: center;
  align-items: center;
  padding-top: 6.375rem;
  font-size: .75rem;
  display: flex;
}

.footer-item {
  color: #6d7076;
  margin: 0 2rem;
  text-decoration: none;
}

.footer-item:hover {
  color: #9497ab;
}

.footer-item:active {
  color: #fff;
}

.checkbox {
  text-align: left;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.checkbox-label {
  color: #686c6b;
  font-size: .75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.checkbox-input {
  box-sizing: border-box;
  border: 1px solid #686c6b;
  border-radius: .1875rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: .875rem;
  position: relative;
  overflow: hidden;
}

.checkbox-input input {
  opacity: 0;
  position: absolute;
}

.checkbox-input input:checked + svg {
  opacity: 1;
}

.checkbox-input svg {
  opacity: 0;
  width: 1.125rem;
  height: 1.125rem;
  transition: all .2s ease-in;
  position: absolute;
  top: 2px;
  left: 2px;
}

.why-stake {
  color: #3c415f;
  margin-bottom: 45px;
  font-size: 2.5rem;
  font-weight: 700;
}

.reasons {
  -webkit-user-select: none;
  user-select: none;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
  margin-bottom: 1rem;
  font-size: 16px;
  display: grid;
  position: relative;
}

.reason-icon {
  background-image: url("crouton-reason.f2fcc318.svg");
  background-size: cover;
  width: 8.625em;
  height: 8.725em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.reason {
  background: #fff;
  height: fit-content;
  transition: all .2s;
}

.reason:nth-child(2n) {
  margin-top: .625em;
  margin-bottom: -.625em;
}

.reason__body {
  align-items: center;
  margin: auto;
  padding: 3.375em 6.25em;
  display: flex;
}

.reason__title {
  color: #3c415f;
  margin-right: 2.5em;
  font-size: 1.25em;
}

.reason__description {
  color: #9497ab;
  text-align: left;
  font-size: 1em;
  line-height: 1.5em;
}

.reason:hover {
  box-shadow: 0 7px 47px #3c60961a;
}

html, body {
  color: #9497ab;
  background: #0e1937;
  min-width: 360px;
  height: 100%;
  font-family: Poppins, Arial;
  font-size: 16px;
}

* {
  margin: 0;
  padding: 0;
}

@media screen and (width <= 960px) and (width >= 769px) {
  .reason__body {
    padding: 10px 20px;
  }

  .reason-icon {
    width: 5.625em;
    height: 5.725em;
  }

  .header__logo {
    margin-left: 0;
  }

  .nav__link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (width <= 769px) {
  .header {
    height: 5.6875rem;
  }

  .header__container {
    justify-content: space-between;
  }

  .header__container > .nav {
    display: none;
  }

  .header__logo {
    margin: 0;
    position: static;
    transform: none;
  }

  .header__burger {
    display: block;
  }

  .main__banner {
    margin-bottom: -7.0625rem;
  }

  .main__footer {
    height: 5.3125rem;
  }

  .section__title {
    padding-top: 4.5625rem;
  }

  .section__description {
    text-align: left;
    padding-bottom: 3.4375rem;
  }

  .section__description br {
    display: none;
  }

  .networks .stake__title {
    padding-bottom: 2.6875rem;
  }

  .networks .stake__description {
    text-align: left;
    margin-bottom: 4.4375rem;
    line-height: 1.875rem;
  }

  .nets {
    padding-bottom: 3.9375rem;
  }

  .nets--test {
    padding-bottom: 0;
  }

  .nets__carousel {
    grid-template-columns: repeat(2, 1fr);
  }

  .reasons {
    font-size: 8px;
  }

  .team {
    padding: 0;
    display: block;
  }

  .team .member {
    width: 322px;
    height: 360px;
  }

  .section {
    padding-bottom: 0;
  }

  .about {
    padding-bottom: 7.3125rem;
  }

  .contact {
    padding-bottom: 4.125rem;
  }

  .contact .section__title {
    padding-top: 6.8125rem;
  }

  .form {
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    max-width: 563px;
    margin-top: .625rem;
  }

  .form .form-group--message {
    grid-area: 3 / 1;
  }

  .form .form-group--name {
    grid-area: 1 / 1;
  }

  .form .form-group--email {
    grid-area: 2 / 1;
  }

  .form .form-group--checkbox {
    grid-area: 4 / 1;
  }

  .form .form-group--submit {
    grid-area: 5 / 1;
  }

  .social {
    margin-top: 4.25rem;
  }

  .footer {
    padding-top: 4rem;
  }
}

@media screen and (width <= 576px) {
  body, html {
    font-size: 14px;
  }

  .nets__carousel {
    grid-template-columns: repeat(1, 1fr);
  }

  .reasons {
    font-size: 16px;
    display: block;
  }

  .reasons .reason-icon {
    display: none;
  }

  .reasons .reason {
    margin: 0 0 1rem;
  }

  .reasons .reason__body {
    flex-direction: column;
    align-items: start;
    padding: 18px 32px;
  }

  .reasons .reason__title {
    margin-bottom: 1rem;
  }

  .reasons .reason__title:before {
    content: "";
    background: #bcebe8;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    margin-right: 1rem;
    display: inline-block;
  }

  .reasons .reason__description {
    line-height: 1.5rem;
  }
}
/*# sourceMappingURL=index.8f65e08d.css.map */

.main {
  background: $background-dark-color;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  &__banner {
    text-align: center;
    color: $white-color;
    position: relative;
    background: url('./assets/banner-bg.min.png');
    background-position: center;
    background-size: cover;
    padding: 18rem 5rem;
    margin-bottom: -4.5rem;
  }

  &__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5.25rem;
    width: 100%;
    border-top: 1px solid $border-color;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__footer-button {
    color: $white-color;
    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 1rem;
    cursor: pointer;
  }

  &__title {
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    font-weight: 400;
  }

  &__description {
    font-size: 2.25rem;
    margin-top: 0;
    margin-bottom: 3rem;
    line-height: 2.9375rem;
    font-weight: 400;
  }

  video {
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
  }
}

.team {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  color: #3C415F;
  margin-bottom: 2.25rem;
}

.team__title {
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 2.625rem;
}

.member {
  position: relative;
  font-weight: 400;
  font-size: 1.25rem;

  &__name {
    padding-bottom: 5px;
  }

  &__image {
    width: 100%;
  }

  &__info {
    position: absolute;
    inset: 0;
    background-color: rgba(0,0,0,.43);
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    transition: all .2s ease;

    padding-bottom: 0;
    opacity: 0;
  }

  &:hover {
    .member__info {
      padding-bottom: 6.125rem;
      opacity: 1;
    }
  }
}

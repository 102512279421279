.why-stake {
  font-weight: 700;
  font-size: 2.5rem;
  color: #3C415F;
  margin-bottom: 45px;
}

.reasons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
  position: relative;

  margin-bottom: 1rem;
  font-size: 16px;
  user-select: none;
}

.reason-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 8.625em;
  height: 8.725em;
  background-image: url('./assets/crouton-reason.svg');
  background-size: cover;
}

.reason {
  background: $white-color;
  height: fit-content;

  &:nth-child(even) {
    margin-top: .625em;
    margin-bottom: -.625em;
  }

  &__body {
    display: flex;
    align-items: center;
    margin: auto;
    padding: 3.375em 6.25em;
  }

  &__title {
    font-size: 1.25em;
    margin-right: 2.5em;
    color: #3C415F;
  }

  &__description {
    color: #9497AB;
    font-size: 1em;
    text-align: left;
    line-height: 1.5em;
  }

  transition: all .2s ease;

  &:hover {
    box-shadow: 0px 7px 47px 0px #3C60961A;
  }
}

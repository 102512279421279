@media screen and (max-width: 960px) and (min-width: 769px) {
  .reason__body {
    padding: 10px 20px;
  }

  .reason-icon {
    width: 5.625em;
    height: 5.725em;
  }

  .header__logo {
    margin-left: 0;
  }

  .nav__link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 769px) {
  .header {
    height: $header-height-s;

    &__container {
      justify-content: space-between;

      & > .nav {
        display: none;
      }
    }

    &__logo {
      position: static;
      margin: 0;
      transform: none;
    }

    &__burger {
      display: block;
    }
  }

  .main {
    &__banner {
      margin-bottom: -7.0625rem;
    }
  }

  .main__footer {
    height: 5.3125rem;
  }

  .section__title {
    padding-top: 4.5625rem;
  }

  .section__description {
    text-align: left;
    padding-bottom: 3.4375rem;

    br {
      display: none;
    }
  }

  .networks {
    .stake__title {
      padding-bottom: 2.6875rem;
    }

    .stake__description {
      text-align: left;
      line-height: 1.875rem;
      margin-bottom: 4.4375rem;
    }
  }

  .nets {
    padding-bottom: 3.9375rem;

    &--test {
      padding-bottom: 0px;
    }

    &__carousel {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .reasons {
    font-size: 8px;
  }

  .team {
    display: block;

    padding: 0;

    .member {
      width: 322px;
      height: 360px;
    }
  }

  .section {
    padding-bottom: 0px;
  }

  .about {
    padding-bottom: 7.3125rem;
  }

  .contact {
    .section__title {
      padding-top: 6.8125rem;
    }

    padding-bottom: 4.125rem;
  }

  .form {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    max-width: 563px;
    margin-top: .625rem;

    .form-group {
      &--message {
        grid-area: 3 / 1;
      }

      &--name {
        grid-area: 1 / 1;
      }

      &--email {
        grid-area: 2 / 1;
      }

      &--checkbox {
        grid-area: 4 / 1;
      }

      &--submit {
        grid-area: 5 / 1;
      }
    }
  }

  .social {
    margin-top: 4.25rem;
  }

  .footer {
    padding-top: 4rem;
  }
}

@media screen and (max-width: 576px) {
  body, html {
    font-size: 14px;
  }

  .nets {
    &__carousel {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .reasons {
    display: block;
    font-size: 16px;

    .reason-icon {
      display: none;
    }

    .reason {
      margin: 0;
      margin-bottom: 1rem;

      &__body {
        flex-direction: column;
        align-items: start;

        padding: 18px 32px;
      }

      &__title {
        margin-bottom: 1rem;
        &:before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          border-radius: 50%;
          background: #BCEBE8;
          margin-right: 1rem;
        }
      }

      &__description {
        line-height: 1.5rem;
      }
    }
  }
}

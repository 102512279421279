.networks {
  .stake__title {
    font-weight: 700;
    font-size: 2.5rem;
    color: $button-color;
    padding-bottom: 2.9375rem;
  }

  .stake__description {
    font-size: 1.25rem;
    margin-bottom: 4.625rem;
  }
}

.nets {
  padding-bottom: 3.375rem;

  &__title {
    font-size: 2.25rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 2.625rem;
  }

  a {
    color: inherit;
    text-decoration: none;

    .net-item__button::after {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      background: url('./assets/link-icon.svg');
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }
}

.nets__carousel {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.25rem;
  margin-bottom: 2.125rem;
}

.net-item {
  background: $white-color;
  display: flex;
  flex-direction: column;

  &__image {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    flex-grow: 1;
    transition: all ease .2s;
  }

  &__caption {
    font-size: 1.25rem;
    flex-grow: 0;
    flex-shrink: 0;
    padding-bottom: 3.375rem;
  }

  &__svg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  &__button {
    background: #FCFAFF;
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: all ease .2s;
  }
}

.nets--main {
  .net-item {
    aspect-ratio: 1;

    &__svg svg,img {
      max-width: 6.25rem;
      max-height: 6.25rem;
    }

    &:hover {
      .net-item__button {
        height: 3.5rem;
        text-decoration: none;
      }
    }
  }
}

.nets--test {
  padding-bottom: 0;

  .net-item {
    aspect-ratio: 1.79;

    &__svg svg,img {
      max-width: 3.625rem;
      max-height: 3.625rem;
    }

    &__caption {
      font-size: 1rem;
      padding-bottom: 1.75rem;
    }

    &__button {
      font-size: .75rem;
    }

    &:hover {
      .net-item__button {
        height: 2.8125rem;
        text-decoration: none;
      }
    }
  }
}

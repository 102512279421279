.contact {
  background: $background-dark-color;
  background-image: url('./assets/footer-bg.min.png');
  color: $white-color;
  background-position: center;
  background-size: cover;

  .section__title {
    padding-top: 8.75rem;
    color: $white-color;
  }

  padding-bottom: 4.125rem;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 1.25rem;
  grid-row-gap: 0.625rem;
  margin-top: -0.5rem;
  max-width: 56.25rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.form-success {
  position: absolute;
  inset: 0;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity .2s ease-in;
  display: none;
}

.form-control {
  border: 1.3px solid #686C6B;
  border-radius: 8px;
  color: #686C6B;
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 1.125rem;
  box-sizing: border-box;
  font-size: .875rem;

  &:hover {
    background: #172240;
  }

  &:focus {
    outline: none;
    background: #fff;
  }
}

.form-group {
  width: 100%;
  height: 3.75rem;
  opacity: 1;
  transition: opacity .2s ease-in;

  &--message {
    grid-area: 1 / 2 / 3 / 3;
    height: 9rem;

    textarea {
      resize: none;
    }
  }

  &--name {
    grid-area: 1 / 1 / 2 / 2;
  }

  &--email {
    grid-area: 2 / 1 / 3 / 2;
  }

  &--checkbox {
    grid-area: 3 / 1 / 4 / 2;
  }

  &--submit {
    grid-area: 3 / 2 / 4 / 3;
  }
}

.form.success .form-group {
  opacity: 0;
}

.form.success .form-success {
  opacity: 1;
  display: flex;
}

.form-group--checkbox {
  padding-top: .75rem;
}

.submit-button {
  width: 100%;
}

.social {
  margin-top: 3.4375rem;

  &-description {
    font-size: 1.25rem;
    color: #6D7076;
    margin-bottom: 1.625rem;
  }

  &-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-link {
    display: block;
    border-radius: 50%;
    border: 1px solid #fff;
    margin: 0 .375rem;

    height: 2.80687rem;
    width: 2.80687rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #172240;
    }

    &:active {
      background: #26304B;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  padding-top: 6.375rem;

  &-item {
    margin: 0 2rem;
    color: #6D7076;
    text-decoration: none;

    &:hover {
      color: #9497AB;
    }

    &:active {
      color: #FFFFFF;
    }
  }
}


.checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  text-align: left;
  user-select: none;
  cursor: pointer;
}

.checkbox-label {
  font-style: normal;
  font-weight: 400;
  font-size: .75rem;
  color: #686C6B;
  line-height: 1rem;
}

.checkbox-input {
  width: 1.5rem;
  height: 1.5rem;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  overflow: hidden;
  margin-right: .875rem;

  border: 1px solid #686C6B;
  border-radius: .1875rem;
  position: relative;

  input {
    opacity: 0;
    position: absolute;
  }

  input:checked + svg {
    opacity: 1;
  }

  svg {
    position: absolute;
    width: 1.125rem;
    height: 1.125rem;
    left: 2px;
    top: 2px;
    opacity: 0;
    transition: all .2s ease-in;
  }
}

.button {
  background: $button-color;
  font-size: 1.25rem;
  padding-top: .9375rem;
  padding-bottom: .9375rem;
  width: 16.8125rem;
  border: 1px solid $button-color;
  border-radius: 1.875rem;
  line-height: 1.875rem;
  cursor: pointer;
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &:disabled {
    background: $button-disabled-color;
    border-color: $button-disabled-color;
  }

  &:hover {
    background: $button-hover-color;
    border-color: $button-hover-color;
  }

  &:active {
    background: $button-click-color;
    border-color: $button-click-color;
  }

  &--light {
    background: transparent;
    color: $button-color;

    &:disabled {
      background: transparent;
      color: $button-disabled-color;
    }

    &:hover {
      background: transparent;
      color: $button-hover-color;
    }

    &:active {
      background: transparent;
      color: $button-click-color;
    }
  }

  img {
    padding-left: .75rem;
  }
}

section {
  text-align: center;
  background: $background-color;
}

.section {
  padding-bottom: 6.125rem;
}

.container {
  max-width: $container-width;
  padding: 0 $container-padding;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.section__title {
  font-size: 3rem;
  font-weight: 700;
  color: $title-color;
  text-transform: uppercase;
  margin: 0;
  padding-top: 6.875rem;
  padding-bottom: 2.75rem;
}

.section__description {
  font-size: 1.25rem;
  line-height: 1.875rem;
  padding-bottom: 4.4375rem;
}

.show-form-btn {
  user-select: none;
  cursor: pointer;
}

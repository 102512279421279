.header {
  width: 100%;
  height: $header-height;
  border-bottom: 1px solid $border-color;
  z-index: 1;
  position: fixed;
  transition: all .2s ease;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
  }

  &--open {
    .burger-btn__icon {
      background: url('./assets/close.svg');
    }
  }

  &__burger {
    display: none;
    position: relative;
  }

  &__logo {
    position: absolute;
    left: $container-padding;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 1.5rem;
    z-index: 1;

    img {
      width: $logo-width;
    }
  }

  &--scrolled {
    background: $background-dark-color;
  }

  &--scrolled-light {
    background: $background-color;

    .header__container {
      & > .nav .nav__link {
        color: #9497AB;

        &--active {
          text-shadow: 0 0 0.4px #3C415F;
          color: #3C415F;
        }
      }
    }
  }
}

.burger-btn {
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  padding: 5px;
  box-sizing: content-box;

  &__icon {
    border: none;
    display: block;
    width: 100%;
    height: 100%;
    background: url('./assets/burger.svg');
  }
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;

  &__link {
    text-decoration: none;
    color: $white-color;
    font-weight: 400;
    font-size: 1rem;
    text-transform: uppercase;
    padding: .5rem 2rem;
    position: relative;
    white-space: nowrap;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: .375rem;
      height: .375rem;
      border-radius: 50%;
      background: #19CA75;
      left: 50%;
      margin-left: -0.1875rem;
      bottom: -0.3125rem;
      transition: all .3s ease;
      opacity: 0;
    }
  }

  &__link.nav__link--active {
    &:after {
      opacity: 1;
    }
  }
}

.header__mobile-nav {
  background-color: rgba(14,25,55,.95);
  backdrop-filter: blur(4px);
  position: fixed;
  inset: 0;

  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .nav {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2.625rem;
  }

  .nav__link {
    font-size: 1.5rem;
    line-height: 2.25rem;
    padding: .9375rem .625rem;

    &:after {
      transition: none;
    }

    &--active {
      &:after {
        inset: 0;
        margin-top: 1.875rem;
        margin-left: -0.75rem;
      }
    }
  }
}

.header--open {
  .header__mobile-nav {
    display: flex;
  }
}

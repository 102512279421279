@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "./src/theme.scss";
@import "./src/common.scss";
@import "./src/components/header/styles.scss";
@import "./src/components/main/styles.scss";
@import "./src/components/networks/styles.scss";
@import "./src/components/about/styles.scss";
@import "./src/components/contact/styles.scss";
@import "./src/components/services/styles.scss";

html, body {
  height: 100%;
  font-family: 'Poppins', Arial;
  font-size: $font-desktop;
  color: $base-color;
  background: $background-dark-color;
  min-width: 360px;
}

* {
  margin: 0;
  padding: 0;
}

@import "./src/media.scss";
